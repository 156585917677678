import '../assets/css/App.css';
import Webcam from 'react-webcam';

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Wear A Mask! 😷✔️
        </p>
        <Webcam 
        />
      </header>
    </div>
  );
}

export default App;
